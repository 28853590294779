import React from "react"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import Content_FeatureTrust from "components/content/feature_trust-backups.js";
import Content_FeatureControl from "components/content/feature_storage-control.js";
import Content_StorageOptimized from "components/content/feature_storage-optimized.js";
import StorageProviders from "./_storageProviders";

const ContentPage = () => (
  <Layout.Layout textColor="dark">
    <Layout.SEO
      title="Automated Cloud Storage Backup on Auto-Pilot"
      description="Schedule storage to storage replication, migration, and backup cross cloud providers. Supports any S3 compliant storage and Dropbox, Google Drive and more."
    />
    <Layout.Hero
      type="primary"
      title={(<>Automate <span className="text-primary">Cloud Storage</span> backup across different providers.</>)}
      subTitle={(
        <>Secure your most precious data by automating replication of your buckets to another storage.
        </>)}
      checkMarks={['Storage migration', 'Automated Storage backup']}
      cta={(<Ui.SignupButton className="mt-6" noSubtitle text="Backup your Storage" urlAtrr={{ sb_source: "website", sb_term: "storage-hero" }} />)}
      illustration={(
        <Ui.Image className="" filename="simplebackups-storage-backup-hero.png" alt="SimpleBackups Storage backup & migration"/>
      )}
      withIllustrationBox={false}
    />

    <Ui.Container>
      <Sections.JoinBrandsSection />
    </Ui.Container>

    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <Ui.FeatureRows items={
            [
              Content_StorageOptimized({subject: null}),
              Content_FeatureTrust(),
            ]}></Ui.FeatureRows>

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section color="alt" id="features">
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center">Supported Providers</Ui.Typography>
          <p className="mb-6 text-center">Effortlessly connect your SimpleBackups account with your favorite storage provider. Select your bucket, your destination storage and start protecting your data.</p>
          <div className="mt-16 text-center">
            <StorageProviders></StorageProviders>
            <div className="mt-16"><Ui.Link arrow="right" to="/catalog/">Discover our Integration catalog</Ui.Link></div>
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Sections.SectionLearn
      title={<>Wanna learn more about Storage Replication?</>}
      text="Find out more and get started with our Storage Replication guides"
      tags={['storage-replication']}
    />

    <Sections.SectionGetStarted/>
    <Sections.SectionTestimonialExtended />
    <Sections.SectionFaq color="alt" faqs={['what_storage_replication_service', 'how_sync_move_s3_backup', 'how_sync_move_drive_to_dropbox', 'how_sync_move_s3_to_s3', 'use_for_client_2', 'use_for_other_things']}/>


  </Layout.Layout>
)

export default ContentPage
